/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import { useState } from 'react';
import Modal from '../../components/Modal';
import copyImg from '../../assets/svg/copy.svg';
import CustomTooltip from '../../components/Tooltip/Tooltip';
import TextField from '../../components/TextField';

const JobShareModal = ({ openDialog, closeDialog, id ,url}) => {
  const [copyText, setCopyText] = useState('Copy to clipboard');
  const handleCopyUrl = async () => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_URL}${url}${id}`);
    setCopyText('Copied to clipboard');
  };

  const EmailShareButton = ({ jobId }) => {
    // Construct the dynamic URL
    const jobDetailsUrl = `${process.env.REACT_APP_URL}${url}${jobId}`;
    // Create the `mailto:` link
    const mailtoLink = `mailto:?subject=&body=${jobDetailsUrl}`;

    return (
      <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
        <EmailIcon size={54} round />
      </a>
    );
  };

  const JobdetailsModalContent = () => (
    <div className="jobsharemodal">
      <div className="share-icons-wrap">
        <WhatsappShareButton url={`${process.env.REACT_APP_URL}${url}${id}`}>
          <WhatsappIcon size={54} round />
        </WhatsappShareButton>

        <FacebookShareButton url={`${process.env.REACT_APP_URL}${url}${id}`}>
          <FacebookIcon size={54} round title="Facebook" />
        </FacebookShareButton>

        <TwitterShareButton url={`${process.env.REACT_APP_URL}${url}${id}`}>
          <XIcon size={54} round title="X" />
        </TwitterShareButton>

        <EmailShareButton jobId={id} />
      </div>
      <div className="copy-box">
        <TextField
          value={`${process.env.REACT_APP_URL}${url}${id}`}
          disabled
          label="Link"
          icon={
            <CustomTooltip title={copyText}>
              <div
                className="copyBtn"
                onClick={() => handleCopyUrl()}
                onMouseOut={() => setCopyText('Copy to clipboard')}>
                <img src={copyImg} alt="" />
                Copy
              </div>
            </CustomTooltip>
          }
        />
      </div>
    </div>
  );

  return (
    <Modal
    openDialog={openDialog}
    closeDialog={closeDialog}
    heading={<div className="title">Share job</div>}
    dialogContent={JobdetailsModalContent()}
    closeButVisible
    dialogCss="sharemodal-wrap"
  />
  );
};

export default JobShareModal;
