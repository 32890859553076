/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
import StyledRecruitersDetailsPage from './StyledRecruitersDetailsPage';
import { checkCompensationType, durationCheck, stringAvatar } from '../../helper';
import backArrow from '../../assets/svg/jobdetail/jobdetail-back-arrow.svg';
import share from '../../assets/svg/share-circle.svg';
import  copyIcon from "../../assets/svg/copy-black.svg";
import industryIcon from '../../assets/svg/jobdetail/jobdetail-industry.svg';
import coin from '../../assets/svg/coin-jobs.svg';
import locationPoint from '../../assets/svg/location-jobs.svg';
import calendar from '../../assets/svg/calendar-jobs.svg';
import designationIcon from '../../assets/svg/user-icon.svg';
import emailIcon from '../../assets/svg/email-icon.svg';
import companyIcon from '../../assets/svg/jobdetail/jobdetail-company.svg';
import locationSmallIcon from '../../assets/svg/jobdetail/jobdetail-location-small.svg';
import smsIcon from '../../assets/svg/jobdetail/jobdetail-sms.svg';
import websiteIcon from '../../assets/svg/jobdetail/jobdetail-website.svg';
import LinkdinIcon from '../../assets/svg/jobdetail/jobdetail-linkdin.svg';
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
import JobShareModal from '../jobDetails/JobShareModal';
import { getRecruiterDetails } from '../../store/reducers/userData/apiThunk';
import CustomTooltip from '../../components/Tooltip/Tooltip';
import backArrowMobile from '../../assets/svg/jobdetail/arrow-left.svg';
import { PrimaryButtonBlue } from '../../components/Button';
import Modal from "../../components/Modal";

const RecruiterDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [recruiter, setRecruiter] = useState(null);
  const [recruiterDetails, setRecruiterDetails] = useState({});
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [copyText, setCopyText] = useState('Copy posted job link');
  const [isOpenSubscription, setisOpenSubscription] = useState(false);

  const handleCloseSubscription=()=>setisOpenSubscription(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCopyUrl = async () => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/job-details?id=${recruiterDetails?.jobs?.[0]._id}`);
    setCopyText('Copied to clipboard');
  };

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };
  const handleOpenShareDialog = () => {
    setRecruiter(recruiter);
    setOpenDialogShare(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setRecruiter(params.get('id'));
    }
  }, [location]);


  const getRecruiterDetail = async () => {
    try {
      const query = `${recruiter}`;
      const response = await dispatch(getRecruiterDetails(query));
      const { payload: { data:{data={}}, status } = {} } = response;
      if (status) {
        setRecruiterDetails(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if(recruiter){
      getRecruiterDetail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiter]);


  const JobDetaisInfo = ({ src, heading, detail }) => (
    <div>
      <div className="info-wrap">
        <img className="info-icon" src={src} alt="icon" />
        <div>
          <div className="info-heading">
            {heading}
          </div>
          <div className="info-detail">
            {detail}
          </div>
        </div>
      </div>
      <div className="info-wrap-mobile">
        <div className="info-heading">
          <img className="info-icon" src={src} alt="icon" />
          <span>
            {heading}
          </span>
        </div>
        <div className="info-detail">
          {detail}
        </div>
      </div>

    </div>
  );
  const JobInfoCard = ({ heading, content }) => (
    <div className="info-card-wrap">
      <div className="info-heading">{heading}</div>
      <div>{content}</div>
    </div>
  );

  const handleBack = () => {
    navigate(-1);
  };

 if(!recruiterDetails) return;

  return (
    <>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100" style={{background:'#F8F4F0'}}>
    <AppHeader text="FRACTIONAL JOB RECRUITERS" />
    <StyledRecruitersDetailsPage>
      <div className="page-wrap">
        <div className="page-title"><img alt="arrow" src={backArrow} onClick={() => handleBack()} /> <span>Recruiter’s Profile</span></div>
        <div className="page-title-mobile-wrap">
          <div className="content">
            <div className="left"> <img alt="arrow" src={backArrowMobile} onClick={() => handleBack()} /> <span>Recruiter’s Profile</span></div>
            <div className="right">
              <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
            </div>
          </div>
        </div>
        <div className="job-details-wrap">
          <div className="top-space" />
          <div className="job-details-content-wrap">
            <div className="job-header">
              <div className="left">
                <div>
                  {recruiterDetails?.profileImagePath ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${recruiterDetails?.profileImagePath}`} alt="" />
                    : <Avatar {...stringAvatar(recruiterDetails?.firstName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{recruiterDetails?.firstName} {recruiterDetails?.lastName}</div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {recruiterDetails?.jobs?.[0]?.industry}</div>
                </div>
              </div>
              <div className="right">
                <div>
                  <div className="date-title">Date of Association</div>
                  <div className="date-desc">{moment(recruiterDetails?.createdAt).format('DD MMMM YYYY')}</div>
                </div>
                <CustomTooltip title={copyText} placement="top">
                  <div
                    className="copyBtn"
                    onClick={() => handleCopyUrl()}
                    onMouseOut={() => setCopyText('Copy posted job link')}
                    >
                    <img className="icon-image" src={copyIcon} alt="" />
                  </div>
                </CustomTooltip>
                <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
              </div>
            </div>
            <div className="job-header-Mobile">
              <div className="left">
                <div>
                  {recruiterDetails?.profileImagePath ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${recruiterDetails?.profileImagePath}`} alt="" />
                    : <Avatar {...stringAvatar(recruiterDetails?.firstName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{recruiterDetails?.firstName} {recruiterDetails?.lastName}</div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {recruiterDetails?.jobs?.[0]?.industry}</div>
                  <div className="date-title">Date of Association: <span className="date-desc">{moment(recruiterDetails?.createdAt).format('DD MMMM YYYY')}</span></div>
                </div>
              </div>
            </div>
            <Grid container columnSpacing={2}>
              <Grid item md={8.5} xs={12}>
                <div className="section-title">Basic Details</div>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={emailIcon} heading="Email" detail={`${recruiterDetails?.primaryEmail}`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={designationIcon} heading="Designation" detail={recruiterDetails?.jobs?.[0]?.designation ||"N/A"} />
                  </Grid>
                </Grid>
                <div className="divider"/>
                <div className="section-title">Subscription</div>
                <div className="subscription-info-wrap">
                  <div className="subscription-top">
                    <div className="sub-heading">You are currently subscribed to</div> 
                    <div className="view-details"  
                    onClick={()=>{
                        setisOpenSubscription(true)
                      }}>View Details</div>
                  </div>
                  <div className="subscription-info-card">
                    <div className="plan-info-wrap">
                      <span className="plan-chip">Half-Yearly Plan</span>
                      <div className="plan-duration">
                        <span className="currency">₹ 1199</span><span className="time">For 6 months</span>
                      </div>
                    </div>
                    <div className="subscription-details-card">
                      <div className="heading">Subscription Start Date:</div>
                      <div className="sub-heading">August 22, 2024</div>
                    </div>
                    <div className="subscription-details-card">
                      <div className="heading">Subscription End Date:</div>
                      <div className="sub-heading">January 22, 2025</div>
                    </div>
                  </div>
                </div>
                <div className="section-title">Jobs Posted</div>
                <div className="job-card-wrap">
                  <div className="card-top">
                    <div className="right">
                      {recruiterDetails?.jobs?.[0]?.companyLogo ? (
                        <img
                          className="brand-image"
                          style={{ borderRadius: '50%' }}
                          src={`${process.env.REACT_APP_IMAGE_URL}/${recruiterDetails?.jobs?.[0]?.companyLogo}`}
                          alt=""
                        />
                      ) : (
                        <Avatar {...stringAvatar(recruiterDetails?.jobs?.[0]?.companyName)} />
                      )}
                      <div>
                        <div className="job-title">{recruiterDetails?.jobs?.[0]?.jobRole}</div>
                        <div className="job-postedby">{recruiterDetails?.jobs?.[0]?.companyName}</div>
                      </div>
                    </div>

                    <div className="left">
                      <div className="date-title">Date of Association</div>
                      <div className="date-desc">
                        {moment(recruiterDetails?.createdAt).format('DD MMMM YYYY')}
                      </div>
                    </div>
                  </div>

                  <div className="skill-chips-box">
                    {recruiterDetails?.jobs?.[0]?.skills.map((skill, key) => (
                      <span className="chips" key={skill}>
                        {skill}
                      </span>
                    ))}
                  </div>
                  <div className="card-bottom">
                    <div className="job-info-content">
                      <div className="icon-text-box-wrap">
                        <div className="icon-text-box">
                          <img alt="" src={locationPoint} />
                          <div className="sub-text">
                            {recruiterDetails?.jobs?.[0]?.jobCity}, {recruiterDetails?.jobs?.[0]?.jobState}
                            <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                            <span style={{ textTransform: 'capitalize' }}>
                              {recruiterDetails?.jobs?.[0]?.locationType}
                            </span>
                          </div>
                        </div>

                        <div className="icon-text-box">
                          <img alt="" src={coin} />
                          <div className="sub-text">
                            ₹ {recruiterDetails?.jobs?.[0]?.compensationRate}/
                            {`${checkCompensationType(recruiterDetails?.jobs?.[0]?.compensationType)}`}
                          </div>
                        </div>

                        <div className="icon-text-box">
                          <img alt="" src={calendar} />
                          <div className="sub-text">
                            {recruiterDetails?.jobs?.[0]?.availabilityPeriod}{' '}
                            {durationCheck(
                              recruiterDetails?.jobs?.[0]?.availableFor,
                              recruiterDetails?.jobs?.[0]?.availabilityPeriod
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="view-details"
                        onClick={() =>
                          navigate(`/job-details?job-id=${recruiterDetails?.jobs?.[0]?._id}`)
                        }
                      >
                        View Details
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item md={3.5} xs={12} mt={4}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="About Company"
                      content={
                        <div className="company-wrap">
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={smsIcon} alt="icon" /> <span>Contact Details</span>
                            </div>
                            <div className="company-detail contact">
                              {recruiterDetails?.jobs?.[0]?.phoneNumber}
                              <a
                                className="job-links"
                                href={recruiterDetails?.jobs?.[0]?.linkedinProfile}
                                target="_blank"
                                rel="noreferrer">
                                <img className="linkdin-icon" src={LinkdinIcon} alt="icon" />
                              </a>
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={locationSmallIcon} alt="icon" /> <span>Company Address</span>
                            </div>
                            <div className="company-detail">
                              {recruiterDetails?.jobs?.[0]?.companyAddress}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={companyIcon} alt="icon" /> <span>Company Type</span>
                            </div>
                            <div className="company-detail">
                              {recruiterDetails?.jobs?.[0]?.companyType}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={websiteIcon} alt="icon" /> <span>Website</span>
                            </div>
                            <div className="company-detail">
                              <a
                                className="job-links"
                                href={recruiterDetails?.jobs?.[0]?.companyWebsite}
                                target="_blank"
                                rel="noreferrer">
                                {recruiterDetails?.jobs?.[0]?.companyWebsite}
                              </a>
                            </div>
                          </div>
                        </div>
                    } />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </StyledRecruitersDetailsPage>
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={recruiter}
          url={`/job-details?job-id=`}
        />
    </div>
    <Modal
        openDialog={isOpenSubscription}
        closeDialog={handleCloseSubscription}
        heading={<div className="title">Subscription Detail</div>}
        dialogContent={
          <div className="content-wrapper">
            <div className="wrap-div">
            <div className="subs-wrap">
            <div className="subs-card">
              <div className="select-plan">
                <div className="plan-duration">Half-Yearly Plan</div>
              </div>
              <div className="plan-amount">
                Rs 1199 <span>For 6 months</span>
              </div>
              <PrimaryButtonBlue style={{width:'198px'}}>View Details</PrimaryButtonBlue>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription Start Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription End Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
          </div>
            </div>
          </div>
        }
        closeButVisible
        dialogCss="accountDetail"
      />
    </>
  );
};

export default RecruiterDetails;
