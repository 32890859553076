import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import LoginPage from '../pages/login';
import DashboardPage from '../pages/dashboard/DashboardLayout';
import PrivateRoute from './privateRoute';
import UserPage from '../pages/users/UserLayout';
import UserDetail from '../pages/users/UserDetail';
import PublicRoute from './PublicRoute';
import Sessions from '../pages/sessions/Sessions';
import FractionalExpert from '../pages/fractionalExpert/FractionalExpert';
import FractionalRecruiters from '../pages/fractionalRecruiters/FractionalRecruiters';
import Jobdetails from '../pages/jobDetails/Jobdetails';
import RecruiterDetails from '../pages/recruitersDetails/RecruiterDetails';
import ExpertDetails from '../pages/expertDetails/ExpertDetails';
// import ComingSoon from '../pages/comingSoon';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <PublicRoute><LoginPage /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.USERS,
    element: <PrivateRoute><UserPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.USERS_DETAIL,
    element: <PrivateRoute><UserDetail /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SESSIONS,
    element: <PrivateRoute><Sessions /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.FRACTIONAL_JOB_RECRUITERS,
    element: <PrivateRoute><FractionalRecruiters/></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.FRACTIONAL_JOB_EXPERTS,
    element: <PrivateRoute><FractionalExpert/></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.JOB_DETAILS,
    element: <PrivateRoute><Jobdetails/></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.RECRUITER,
    element: <PrivateRoute><RecruiterDetails/></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.EXPERT,
    element: <PrivateRoute><ExpertDetails/></PrivateRoute>,
  },
]);

export default routes;
