import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrimaryButton = styled(Button)(
  (props) => `
  background: ${props.theme.palette.primary.main};
  border-radius: 4px;
  height: 45px;
  text-transform: none;
  width: 100%;
  font-weight: 800;
  fontSize: 16px;
  lineHeight: 22px;
  color: #FFFFFF;
  boxShadow: none;
  border: none;
  whiteSpace: nowrap;
  &:hover {
    background-color: ${props.theme.palette.primary.main};
    border: 1px solid ${props.theme.palette.customColor.primaryWhite};
    color: ${props.theme.palette.customColor.primaryWhite};
  }
  &.Mui-disabled{
    background-color: #3F3F3F;
    color: ${props.theme.palette.customColor.primaryWhite};
  }
`,
);

export const SecondaryButton = styled(Button)(
  (props) => `
  background: ${props.theme.palette.customColor.secondaryBlack};
  color: ${props.theme.palette.customColor.primaryWhite};
  border-radius: 4px;
  text-transform: none;
  height: 45px;
  width: 100%;
  font-weight: 700;
  fontSize: 16px;
  lineHeight: 24px;
  boxShadow: none;
  justify-content: space-evenly;
  &:hover{
    background-color:${props.theme.palette.primary.main};
    color: ${props.theme.palette.customColor.primaryWhite};
  }
  &.Mui-disabled{
    color: rgb(255 255 255 / 40%);
  }
`,
);
export const PrimaryButtonBlue = styled(Button)(
  (props) => `
  background: ${props.theme.palette.customColor.blue};
  border-radius: 4px;
  padding:10px 12px;
  height:34px;
  text-transform: none;
  width: 100%;
  font-weight: 600;
  fontSize: 14px;
  lineHeight: 18px;
  color: #FFFFFF;
  boxShadow: none;
  border: none;
  whiteSpace: nowrap;
  &:hover {
    background-color: ${props.theme.palette.customColor.blue};
    color: ${props.theme.palette.customColor.primaryWhite};
  }
  &.Mui-disabled{
    background-color: #3F3F3F;
    color: ${props.theme.palette.customColor.primaryWhite};
  }
`,
);

export const BorderButton = styled(Button)(
  (props) => `
  background: white;
  border: 1px solid #415DF3;
  border-radius: 10px;
  text-transform: none;
  min-height: 34px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #415DF3;
  font-family: Plus Jakarta Sans;
  box-shadow: none;

  &:hover {
    background-color: #415DF3;
    color: white; // Change text color on hover
  }

  &.Mui-disabled {
    color: ${props.theme.palette.customColor.grey_7};
    background: ${props.theme.palette.customColor.grey_5};
    border: 1px solid ${props.theme.palette.customColor.grey_7};
  }
`,
);