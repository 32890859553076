import React from "react";
import { CNavItem } from "@coreui/react";
import DashboardIcon from "./assets/svg/dashboard.svg";
import Avatar from "./assets/svg/userBlack.svg";
import RecruitersIcon from "./assets/svg/teacher.svg";
import ExpertIcon from "./assets/svg/briefcase.svg";
const _nav = [
  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: "16px",
        }}
      >
        Dashboard
      </div>
    ),
    to: "/dashboard",
    icon: (
      <img
        alt="dashboard"
        style={{
          width: "30px",
        }}
        src={DashboardIcon}
      />
    ),
  },

  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: "16px",
        }}
      >
        All Users
      </div>
    ),
    to: "/users",
    icon: (
      <img
        alt="user"
        style={{
          width: "30px",
        }}
        src={Avatar}
      />
    ),
  },

  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: "16px",
        }}
      >
        Fractional Job Recruiters
      </div>
    ),
    to: "/fractional-recruiters",
    icon: (
      <img
        alt="recruiters-icon"
        style={{
          width: "30px",
        }}
        src={RecruitersIcon}
      />
    ),
  },

  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: "16px",
        }}
      >
        Fractional Job Experts
      </div>
    ),
    to: "/fractional-expert",
    icon: (
      <img
        alt="expert-lcon"
        style={{
          width: "30px",
        }}
        src={ExpertIcon}
      />
    ),
  },
];

export default _nav;
