const ROUTE_PATH = {
  PAGE404: "*",
  LANDING: "/",
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_DETAIL: "/users-detail",
  SESSIONS: "/sessions",
  FRACTIONAL_JOB_RECRUITERS: "/fractional-recruiters",
  FRACTIONAL_JOB_EXPERTS: "/fractional-expert",
  JOB_DETAILS: "/job-details",
  RECRUITER: "/recruiter/",
  EXPERT: "/expert/",
};

const API_VERSION = {
  V1: "/api/app/v1",
};

const API_PATH = {
  LOGIN: `${API_VERSION.V1}/admin/login`,
  LOGOUT: `${API_VERSION.V1}/admin/logout`,
  DASHBOARD: `${API_VERSION.V1}/admin/dashboard-details`,
  EXPERTLIST: `${API_VERSION.V1}/admin/expert-list`,
  EXPERTDETAIL: `${API_VERSION.V1}/admin/expert-activity-report/`,
  EXPERTTICKETRESOLVED: `${API_VERSION.V1}/admin/resolve-ticket/`,
  SESSIONLIST: `${API_VERSION.V1}/admin/upcoming-sessions`,
  PAYMENTSESSION: `${API_VERSION.V1}/admin/expert-payment-status/`,
  FRACTIONEXPERTLIST: `${API_VERSION.V1}/admin/all-expert-list`,
  FRACTIONRECRUITERLIST: `${API_VERSION.V1}/admin/all-recruiter-list`,
  EXPERT_EXPORT: `${API_VERSION.V1}/admin/expert-export-list`,
  RECRUITER_EXPORT: `${API_VERSION.V1}/admin/recruiter-export-list`,
  SEARCHING_FILTERS: `${API_VERSION.V1}/recruiter/get-searching-filters`,
  RECRUITER_DETAILS_RESUME: `${API_VERSION.V1}/expert/download-resume/`,
  EXPERT_DETAILS: `${API_VERSION.V1}/expert-details/`,
  RECRUITER_DETAILS: `${API_VERSION.V1}/admin/recruiter-details/posted-jobs/`,
  GET_RECRUITER_JOB_DETAILS: `${API_VERSION.V1}/job-details/`,
};

export { ROUTE_PATH, API_PATH };
