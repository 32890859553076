import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Chip,
  Box,
} from "@mui/material";
import "./CustomTable.scss";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';


export const SESSION_STATUS_ENUM = {
  PENDING: 1,
  IN_PROGRESS: 2,
  UPCOMING: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  RACE: 6,
};

// Map numeric values to status strings
const STATUS_MAP = {
  [SESSION_STATUS_ENUM.PENDING]: "FREE",
  [SESSION_STATUS_ENUM.IN_PROGRESS]: "IN_PROGRESS",
  [SESSION_STATUS_ENUM.UPCOMING]: "UPCOMING",
  [SESSION_STATUS_ENUM.COMPLETED]: "COMPLETED",
  [SESSION_STATUS_ENUM.CANCELLED]: "CANCELLED",
  [SESSION_STATUS_ENUM.RACE]: "RACE",
};

export const getStatusChip = (status) => {
  const statusString = STATUS_MAP[status]; // Convert numeric status to string

  switch (statusString) {
    case "COMPLETED":
      return (
        <Chip label="COMPLETED" color="success" className="completedChip" />
      );
    case "CANCELLED":
      return <Chip label="CANCELLED" color="error" className="cancelledChip" />;
    case 'UPCOMING':
      return <Chip label="UPCOMING" color="error" className="pendingChip" />;
    default:
      return  <Chip label="MONTHLY" color="success" className="completedChip" />// Handle other statuses or invalid values
  }
};


export default function RecruitersTable({
  sessionsData = [],
  tableHeaderRight = false,
  totalRecruiters=0,
}) {
  const navigate = useNavigate()

  const handleViewDetails =(id)=>{
    navigate(`/recruiter?id=${id}`)
  }

  return (
    <>
      <Box sx={{ padding: 3 }} className="fractionTableWrapper">
        <TableContainer component={Paper}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            className="table-header-wrap"
          >
            <Typography variant="h6">Total Recruiters: {totalRecruiters}</Typography>
            {tableHeaderRight ? tableHeaderRight : ""}
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Date of association</TableCell>
                <TableCell>About Company</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionsData.length ? sessionsData?.map((session, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell>
                    <Typography className="typography-cell" >
                      {session.firstName + ' ' + session.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className="typography-cell" >
                      {session.phoneNumber}
                    </Typography>
                    <Typography  className="typography-cell" color="textSecondary">
                      {session.primaryEmail}
                    </Typography>
                    </TableCell>
                  <TableCell>
                    <Typography className="typography-cell">
                    {dayjs(session.createdAt).format(
                      "MMM DD, YYYY"
                    )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography className="typography-cell">
                      {session.companyName}
                    </Typography>
                    <Typography variant="body2">
                      {session.companyAddress}
                    </Typography>
                  </TableCell>
                  <TableCell>{getStatusChip(session.status)}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="primary"
                      className="tableButton"
                      onClick={()=>handleViewDetails(session._id)}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              )):null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
