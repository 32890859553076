import { styled } from "@mui/material/styles";
import applicantDetailFrame from "../../assets/images/ApplicantDetailFrame.png";
import myServiceFrame from "../../assets/svg/myServicesBG.svg";
import myServiceFrameMob from "../../assets/svg/expertbg.svg";

const StyledApplicantDetails = styled("div")(
  ({ theme }) => `
  .content-wrap {
    padding: 30px 50px;
    @media screen and (max-width: 900px){
      padding:0px;
      margin-top: -36px
    }
  }
  .back-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    svg {
      cursor: pointer; 
    }
    @media screen and (max-width: 900px){
      display:done;
    }
  }
  .page-title-mobile-wrap{
    display:none;
    background: #ffffff;
    z-index: 1400;
    position: sticky;
    top: 0px;
    height: 79px;
    margin:-79px 0px 16px 0px;
    padding:16px 20px;
    .content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            align-items: center;
            gap:12px;
            font-family: Plus Jakarta Sans;
            font-size: 18px;
            font-weight: 700;
            line-height: 22.68px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${theme.palette.customColor.primaryBlack};
            img{
                cursor:pointer;
            }
        }
        .right{
            display: flex;
            align-items: center;
            gap:12px;
            .icon-image{
                width:32px;
                height:32px;
                cursor:pointer
            }
        }
    }
    @media screen and (max-width: 900px){
        display: flex;
    }
  }
  .back-btn-text {
    color: ${theme.palette.customColor.primaryBlack};
    font-style: normal;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
    font-size: 24px;

  }
  .detail-card {
    position: relative;
    margin-top: 20px;
    width: 100%;
    border: 0px solid;
    border-radius: 24px;
    background: white;
    min-height: 40vh;
    .img-back {
      width: 100%;
      height: 63px;
      background-image: url(${applicantDetailFrame});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 26px 26px 0px 0px;
      @media screen and (max-width: 900px){
        height: 38px;
        background-image: url(${myServiceFrameMob});
        width:100%;
        border-radius-top-left:12px;
        border-radius-top-right:12px;
      }
    }
  } 
  .main-card {
    .header {
      margin: 10px 20px;
      @media screen and (max-width: 900px){
        margin: 0px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-header {
        display: flex;
        align-items: baseline;
        .img {
          position: relative;
          width:130px;
          @media screen and (max-width: 900px){
            width: 64px;
            padding:0px 12px;
          }
          .img-wrap {
            border: 0px solid white;
            background: white;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            position: absolute;
            top: -75px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 900px){
              width: 64px;
              height: 64px;
              top: -40px;
            }
          }
        
        }
        .applicant {
            margin-left: 32px;
            @media screen and (max-width: 900px){
              margin-left: 24px;
            }
            .applicant-name {
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Sora";
              font-size: 28px;
              font-style: normal;
              text-align: left;
              font-weight: 700;
              @media screen and (max-width: 900px){
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
              }
            }
            .applicant-role {
             display: flex;
             gap: 10px;
             aligin-items: center;
             color: #415DF3;
             font-weight: 600;
             font-size: 18px;
             font-style: normal;
             text-align: left;
             font-family: "Plus Jakarta Sans";
             @media screen and (max-width: 900px){
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
            }
            }
          }    
      }
      .right-header {
        display: flex;
        gap: 10px;
        align-items: center;
        img{
          cursor: pointer;
        } 
        @media screen and (max-width: 900px){
          display:none;
        }
      }
    }
    .main-body-card {
      margin: 32px 20px 20px;
      display: flex;
      align-items: start;
      @media screen and (max-width: 900px){
        flex-direction:column;
        margin-top:0px;
      }
    
    }
    .subs-cards {
      width: 25%;
      @media screen and (max-width: 900px){
        width: 100%;
      }
      .card-1 {
        border-radius : 20px;
        background-color: #F8F4F0;
        padding: 20px;
        .card-head {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          text-align: left;
          font-weight: 600;
          @media screen and (max-width: 900px){
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 600;
            line-height: 20.16px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }
      }
      .resume-card {
        margin-top: 20px;
        border-radius: 20px;
        background: white;
        padding: 20px;
        .resume {
            display: flex;
            gap: 10px;
            align-items: self-start;
            margin-bottom: 20px;
          .resume-main {
            text-align: left;
            .resume-topic {
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                text-align: left;
                font-weight: 600;
                color: ${theme.palette.customColor.primaryBlack};
            }
            .resume-body {
                color: #757575;
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-style: normal;
                text-align: left;
                font-weight: 500;
            }    

          }
        }

      }  
      .price {
        display: flex;
        gap: 10px;
        align-items: baseline;
        margin-top: 20px;
        .price-card {
          text-align: left;
          .price-text {
            color: ${theme.palette.customColor.primaryBlack};
            font-size: 20px;
            font-style: normal;
            text-align: left;
            font-weight: 700;
            font-family: "Sora";
          }
          .price-duration {
            color: #525252;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-weight: 500;
            font-family: "Plus Jakarta Sans";
          }
        }
      }
      .nego {
        margin: 0px opx 20px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top:10px;
        .nego-text {
            font-style: normal;
            text-align: left;
            font-weight: 600;
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            color: #525252;
            span {
              color: #415DF3;
            }
        }
      }
      .subs {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        align-items: self-start;
        .subs-main {
        text-align: left;
        .subs-topic {
            font-style: normal;
            color: #757575;
            text-align: left;
            font-weight: 600;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
        }
         .subs-body {
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            text-align: left;
            font-weight: 500;
            color: ${theme.palette.customColor.primaryBlack};
         }   
        }
      }      
    }   
    .main-body {
       width: 75%;
       @media screen and (max-width: 900px){
        width:100%;
      }
       .my-services {
        position:relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        background-image: url(${myServiceFrame});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0px 35px 8px 35px;
        height: 70px;
        color:#415DF3;
        @media screen and (max-width: 900px){
          width:100%;
          display:none;
        }
       }
      .body-header {
        color: ${theme.palette.customColor.primaryBlack};
        font-weight: 600;
        font-family: Plus Jakarta Sans;
        font-size: 20px;
        font-style: normal;
        text-align: left;
        margin-bottom: 20px;
        @media screen and (max-width: 900px){
          display: flex;
          font-family: Plus Jakarta Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.16px;
          text-align: left;
        }
      }
      .body-topics {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .each-topic {
          flex: 1 1 45%; 
          display: flex;
          gap: 20px;
          align-items: center;
          .topic-head {
            color: #757575;
            font-weight: 600;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-family: "Plus Jakarta Sans";
          }
          .topic-body {
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 600;
            font-size: 18px;
            font-style: normal;
            text-align: left;
            font-family: "Sora";
          }  
        }
        @media screen and (max-width: 900px){
          display:none;
        }
      }
      .info-wrap-mobile{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:7px;
        margin-bottom:16px;
        .info-heading{
            display: flex;
            align-items: center;
            gap:7px;
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 600;
            line-height: 15.12px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;                    
            color:#757575;
            .info-icon{
                width:24px;
                height:24px;
            }
          }
        .info-detail{
            font-family: Sora;
            font-size: 12px;
            font-weight: 600;
            line-height: 15.12px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: ${theme.palette.customColor.primaryBlack};
            margin-top:4px;
          }
          display:none;
          @media screen and (max-width: 900px){
            display: flex;
          }
        }

      .skills-head {
        margin-top: 30px;
        color: #757575;
        font-size: 14px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 600;
        @media screen and (max-width: 900px){
          margin-top: 0px;
        }
        }       
      }
      .skills {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .user-application-2 {
            width: auto;
            background-color: #415DF314;
            padding: 5px 10px;
            border-radius: 12px;
            color: #415DF3;
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }  
      .about-me {
        margin-top: 20px;
        color: #757575;
        font-size: 14px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 600;
      }
      .about-desc {
        padding-bottom: 30px;
        color: #1C1C1C;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 500;
        white-space: pre-wrap;
      }
      .divider{
        display:none;
        height:1px;
        background:#E2E4E7;
        margin:20px 0px;
        width:100%;
        @media screen and (max-width: 900px){
            display:block;
        }
      }
  }   
  `
);

export default StyledApplicantDetails;
