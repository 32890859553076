import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import AppSidebar from "../../components/AppSidebar";
import AppHeader from "../../components/AppHeader";
import "./fractionalExpert.scss";
import {
  InputAdornment,
  styled,
  TextField,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { exportFractionExpert, fetchFractionExpertList, fetchSearchingFilters } from "../../store/reducers/userData/apiThunk";
import { CSmartPagination } from "@coreui/react-pro";
import {  FormControl } from "@mui/base";
import ExpertTable from "../../components/Table/ExpertTable";
import exportIcon from '../../assets/svg/export.svg';
import { PrimaryButtonBlue } from "../../components/Button";
import { formatLabel } from "../../utils/validations/common";

const CustomSearch = styled(TextField)(() => ({
  width:'260px',
  background: "white",
  borderRadius: "6px",
  border: "1px solid #d9dde3",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    height: "6px",
  },
}));

export default function FractionalExpert() {
  const dispatch = useDispatch();
  const [sessions, setSessions] = useState([]);
  const [search, setSearch] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalExpert, setTotalExperts] = useState(0);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [filtersData, setFiltersData] = useState({
    domainList: [],
  });

  const [checked, setChecked] = useState({
    domainList: [],
    industryList: [],
    jobRoleList: [],
    skillList: [],
    jobTypes: [],
  });
  const [clearFilter, setClearFilter] = useState(false);

  const searchingFilters = async () => {
  const response = await dispatch(fetchSearchingFilters());
  const { payload: {  data: { data = {} } } } = response;
  setFiltersData(data)
}


  useEffect(() => {
    searchingFilters()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchFractionExperts = async () => {
    const requestData = `${`?search=${debouncedSearch}&skill=${checked?.skillList}&domains=${checked?.domainList}&industries=${checked?.industryList}&jobTypes=${checked.jobTypes}&jobRoles=${checked.jobRoleList}&page=${activePage}&limit=10`}`;
    try {
      const dashboardDetails = await dispatch(
        fetchFractionExpertList(requestData)
      );
      const {
        payload: { data :{page=0,expertDetails={},count=0}={} ,status = "" }} = dashboardDetails;
   
      if (status) {
        setSessions(expertDetails);
        setTotalExperts(count)
        setTotalPages(page);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

   // Debouncing the search input by 400ms
   useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 400);

    // Cleanup timeout if the component unmounts or search changes
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch) {
      setActivePage(1); // Reset page to 1 when search changes
    }
    fetchFractionExperts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, activePage, clearFilter]);


const exportListHandler = async (name) => {
  const requestData = `?name=${debouncedSearch}&skills=${checked?.skillList}&domains=${checked?.domainList}&industries=${checked?.industryList}&jobTypes=${checked.jobTypes}&jobRoles=${checked.jobRoleList}`;
  const response = await dispatch(exportFractionExpert(requestData));
  const { payload: { data, status } = {} } = response;

  if (status && data) {
    // Convert the data to a Uint8Array
    const arrayBuffer = new Uint8Array(data.data);
    const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.xlsx`;
    document.body.appendChild(a);
    a.click();
    // Clean up the URL and remove the link element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);  
  }
};


 // Initialize checked state with empty arrays for each filter category
 useEffect(() => {
  const initialCheckedState = Object.keys(filtersData).reduce((acc, key) => {
    acc[key] = []; // Start with empty arrays for each category
    return acc;
  }, {});
  setChecked(initialCheckedState);
}, [filtersData]);

const handleToggle = (filterKey, itemName) => {
  setChecked((prevChecked) => {
  // Ensure that the current filter (filterKey) exists and is an array
    const currentChecked = prevChecked[filterKey] || [];

    // Check if the item is already checked
    const isChecked = currentChecked.includes(itemName);

    // Update the checked state based on whether the item was checked or unchecked
    const updatedChecked = isChecked
      ? currentChecked.filter((item) => item !== itemName) // Remove item
      : [...currentChecked, itemName]; // Add item

    return {
      ...prevChecked, // Spread previous state to preserve other filters
      [filterKey]: updatedChecked, // Only update the specific filter key
    };
  });
};

// Handle expand/collapse toggle
const handleExpand = (key) => {
  setExpandedKeys((prev) => ({
    ...prev,
    [key]: !prev[key], // Toggle expanded state
  }));
};

const handleClear = () => {
  setChecked({
    domainList: [],
    industryList: [],
    jobRoleList: [],
    skillList: [],
    jobTypes: [],
  });
  setSearch('');
  setActivePage(1)
  setClearFilter(!clearFilter)
};

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader text="FRACTIONAL JOB EXPERTS" />
          <Grid container columnSpacing={3} sx={{marginY:"24px"}}>
            <Grid item md={3} xs={12}>
              <div className="filters-wrap">
                  <div className="section-title">Filter</div>
                  <div className="section-content">
                    {Object.keys(filtersData).map((key) => (
                      <div key={key}>
                        <div className="section-heading">{formatLabel(key.replace('List', ''))}</div>
                        {filtersData[key]
                          .slice(0, expandedKeys[key] ? filtersData[key].length : 4)
                          .map((item,idx) => (
                            <ListItemButton
                              key={item._id+idx}
                              role="listitem"
                              // Use `key` as `filterKey
                              onClick={() => handleToggle(key, item.name)}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={checked[key]?.includes(item.name) || false}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': item._id,
                                  }} />
                              </ListItemIcon>
                              <ListItemText id={item._id} primary={` ${item.name}`} sx={{textTransform:"capitalize"}}/>
                            </ListItemButton>
                          ))}
                        {filtersData[key].length > 4 && !expandedKeys[key] && (
                        <span className="more-text" onClick={() => handleExpand(key)}>
                          {`+${filtersData[key].length - 4} more`}
                        </span>
                        )}
                      </div>
                    ))}
                  </div>
                  {filtersData.domainList.length ?
                  <div className="filter-buttons">
                    <span className="clear-btn-filter" onClick={handleClear}>Clear</span>
                    <PrimaryButtonBlue variant="contained" sx={{ width: '115px' }} onClick={()=>{ setActivePage(1); fetchFractionExperts()}}>Apply Filter</PrimaryButtonBlue> </div>
                  :null }
                  </div> 
              </Grid>
              <Grid item md={9} xs={12}>
                <div className="body flex-grow-1">
                  <div>
                    <ExpertTable
                    totalExperts={totalExpert}
                    sessionsData={sessions}
                    fetchSession={()=>fetchFractionExperts()}
                      tableHeaderRight={
                        <div className="wrapper-header">
                          <CustomSearch
                            variant="outlined"
                            placeholder="Search By Name, Email"
                            value={search}
                            onChange={(e)=>handleSearch(e)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}

                          />
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <div> 
                        <button className="fraction-export-button" onClick={()=>exportListHandler('Experts')}>
                          <span><img src={exportIcon} alt=""  /></span> <span>Export list</span>
                        </button>
                      </div>
                          </FormControl>
                        </div>
                      }
                    />
                  </div>
                  <div className='table-paginate'>
                  <CSmartPagination
                    className="cursor_style"
                    align="end"
                    activePage={activePage}
                    pages={totalPages}
                    onActivePageChange={setActivePage}
                  />
                  </div>
                </div>
              </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
