import { styled } from "@mui/material/styles";

const StyledJobDetailsPage = styled("div")(
  ({ theme }) => `
.page-wrap{
    margin:32px 40px;
    @media screen and (max-width: 900px){
      margin:0px;
    }
    .page-title{
        text-align: left;
        margin-bottom:24px;
        img {
          cursor: pointer;
        }
        span{
            font-family: Plus Jakarta Sans;
            font-size: 24px;
            font-weight: 600;
            line-height: 30.24px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color:#1C1C1C;
            margin-left:12px;
        }
        @media screen and (max-width: 900px){
          display: none;
      }
    }
    .page-title-mobile-wrap{
      display:none;
      background: #ffffff;
      z-index: 1400;
      position: sticky;
      top: 0px;
      height: 79px;
      margin:-79px 0px 16px 0px;
      padding:16px 20px;
      .content{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left{
              display: flex;
              align-items: center;
              gap:12px;
              font-family: Plus Jakarta Sans;
              font-size: 18px;
              font-weight: 700;
              line-height: 22.68px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: ${theme.palette.customColor.primaryBlack};
              img{
                  cursor:pointer;
              }
          }
          .right{
              display: flex;
              align-items: center;
              gap:12px;
              .icon-image{
                  width:32px;
                  height:32px;
                  cursor:pointer
              }
          }
      }
      @media screen and (max-width: 900px){
          display: flex;
      }
    }
    .job-details-wrap{
        position:relative;
        border-top:10px solid #FFD2AE;
        border-radius: 24px;
        background:#ffffff;
        .top-space{
            height:18px;
            background:#ffffff;
            border-radius: 7px;
        }
        .job-details-content-wrap{
            background:#ffffff;
            padding:0px 24px 24px;
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
            .job-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    display: flex;
                    align-items: start;
                    flex-wrap:wrap;
                    flex-grow: 1;
                    gap:12px;
                    .brand-image{
                        width: 72px;
                        height: 72px;
                        border-radius: 50%;
                    }
                    .commpany-info{
                        max-width:530px;
                        .job-title{
                            display: flex;
                            gap:4px;
                            align-items: center;
                            font-family: Sora;
                            font-size: 28px;
                            font-weight: 700;
                            line-height: 35.28px;
                            text-align: left;                       
                            color: ${theme.palette.customColor.primaryBlack};
                            word-break:break-all;
                        }
                        .job-industry{
                            display: flex;
                            gap:4px;
                            align-items: center;
                            font-family: Plus Jakarta Sans;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 22.68px;
                            text-align: left;
                            color: #525252;
                            word-break:break-all;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: start;
                    flex-wrap:wrap;
                    gap:16px;
                    .date-title{
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color:#757575;
                    }
                    .date-desc{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.16px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: ${theme.palette.customColor.primaryBlack};
                    }
                    .icon-image{
                        width: 44px;
                        height: 44px;
                        cursor:pointer;
                    }
                }
         
                @media screen and (max-width: 900px){
                  display:none;
                }
            }
            .job-header-Mobile{

              .left{
                display: flex;
                align-items: start;
                flex-wrap:wrap;
                flex-grow: 1;
                gap:12px;
                .brand-image{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
                .commpany-info{
                    max-width:530px;
                    .job-title{
                        display: flex;
                        gap:4px;
                        align-items: center;
                        font-family: Sora;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 25.2px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: ${theme.palette.customColor.primaryBlack};
                        word-break:break-all;
                    }
                    .job-industry{
                        display: flex;
                        gap:4px;
                        align-items: center;
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17.64px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #525252;
                        word-break:break-all;
                    }
                    .date-title{
                      font-family: Plus Jakarta Sans;
                      font-size: 12px;
                      font-weight: 600;
                      line-height: 15.12px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                      color:#757575;
                    }
                  .date-desc{
                      font-family: Plus Jakarta Sans;
                      font-family: Plus Jakarta Sans;
                      font-size: 12px;
                      font-weight: 600;
                      line-height: 15.12px;
                      text-align: left;
                      text-underline-position: from-font;
                      text-decoration-skip-ink: none;
                      color: ${theme.palette.customColor.primaryBlack};
                  }
                }
                display:none;
                @media screen and (max-width: 900px){
                  display: flex;
                }

            }

            }

            //  card content

            .section-title{
                font-family: Plus Jakarta Sans;
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: ${theme.palette.customColor.primaryBlack};
                margin:32px 0px 16px 0px; 
                @media screen and (max-width: 900px){
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 20.16px;
                  margin:16px 0px 16px 0px;
                }
              }
            .subscription-info-wrap{
                padding:16px 20px;
                border-radius:12px;
                background:#FFFFFF;
                border:1px solid #E2E4E7;
                .subscription-top {
                    display:flex;
                    align-items: center;
                    justify-content: space-between;
                    .sub-heading { 
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17.64px;
                        text-align: left;
                        color:#525252;
                    }
                    .view-details {
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        text-decoration:underline;
                        color:#415DF3;
                        cursor:pointer;
                        min-width:84px;
                    }
                }
                .subscription-info-card{
                  border-radius:12px;
                  padding:12px;
                  text-align: left;
                  display:flex;
                  flex-wrap:wrap;
                  gap:16px;
                  .plan-info-wrap{
                      flex-grow:1;
                      border:1px solid #E2E4E7;
                      padding:12px;
                      border-radius:12px;
                    .plan-chip{
                        background:#415DF333;
                        padding:4px 8px;
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15.12px;
                        text-align: left;
                        color:#415DF3;
                        border-radius:50px;

                      }
                    .plan-duration{
                        margin-top:12px;
                        display:flex;
                        align-items:center;
                        gap:8px;
                        justify-content:start;
                        .currency{
                            font-family: Sora;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 30.24px;
                            text-align: left;
                            color:#1C1C1C;
                        }
                        .time{
                          font-family: Plus Jakarta Sans;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 17.64px;
                          text-align: left;
                          color:#525252;
                        }
                    }
                }
                  .MuiButtonBase-root{
                    min-height:34px;
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                  }
                }
                .subscription-details-card {
                    background:#F4F4F4;
                    padding:20px 16px;
                    border-radius:12px;
                    flex-grow:1;
                    .heading{
                      font-family: Plus Jakarta Sans;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 24px;
                      text-align: left;
                      color:#757575;
                      margin:0px;
                    }
                    .sub-heading{
                      font-family: Plus Jakarta Sans;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: left;
                      color:#1C1C1C;
                      margin:0px;
                    }
                  }  
                }
            }   
            .info-wrap{
                display: flex;
                align-items: start;
                flex-wrap:wrap;
                gap:7px;
                margin-bottom:16px;
                .info-heading{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color:#757575;
                }
                .info-detail{
                    font-family: Sora;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22.68px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    margin-top:4px;
                }
                @media screen and (max-width: 900px){
                  display: none;
              }
            }
            .info-wrap-mobile{
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap:7px;
              margin-bottom:16px;
              .info-heading{
                  display: flex;
                  align-items: center;
                  gap:7px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.12px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;                    
                  color:#757575;
                  .info-icon{
                      width:24px;
                      height:24px;
                  }
                 
              }
              .info-detail{
                  font-family: Sora;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.12px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  color: ${theme.palette.customColor.primaryBlack};
                  margin-top:4px;
              }
              display:none;
              @media screen and (max-width: 900px){
                  display: flex;
              }
            }
            .info-card-wrap{
                padding:20px;
                background-color:#F8F4F0;
                border-radius:20px;
                .info-heading{
                    font-family: Plus Jakarta Sans;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25.2px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    margin-bottom:24px;
                }
                // charges
                .company-wrap{
                    text-align: left;
                    .company-heading{
                        display:inline-flex;
                        align-items: center;
                        flex-wrap:wrap;
                        gap:4px;
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        color: #757575;
                    }
                    .company-detail{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.16px;
                        text-align: left;                     
                        color: ${theme.palette.customColor.primaryBlack};
                        margin-left:22px;
                    }
                    .job-links{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20.16px;
                        text-align: left;
                        color:#415DF3;
                        max-width:305px;
                        text-overflow: ellipsis;
                      }
                    .contact{
                        display:flex;
                        align-items:center;
                        justify-content: space-between;
                    }
                }
            }
        }
        
    }
    .mt-16 {
        margin-top:16px;
    }
    .job-card-wrap {
          padding: 12px;
          border-radius: 10px;
          background: ${theme.palette.secondary.main};
          border: 1px solid #e2e4e7;
      
          .card-top {
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 16px;
      
            .right {
              display: flex;
              align-items: start;
              gap: 8px;
              max-width:80%;
              word-break:break-all;
      
              .brand-image {
                width: 40px;
                height: 40px;
                gap: 0px;
                border-radius: 50%;
                
                @media screen and (max-width: 900px) {
                  min-width: 40px;
                  max-width: 40px;
                  border-radius: 50%;
                }
              }
      
              .job-title {
                font-family: Sora;
                font-size: 16px;
                font-weight: 600;
                line-height: 20.16px;
                text-align: left;
                color: ${theme.palette.customColor.primaryBlack};
              }
      
              .job-postedby {
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 400;
                line-height: 17.64px;
                text-align: left;
                color: #525252;
              }
      
              .skill-match {
                font-family: Plus Jakarta Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 15.12px;
                text-align: left;
                color: #00af31;
              }
            }
      
            .left {
              .date-title{
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.64px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color:#757575;
              }  
               @media screen and (max-width: 900px) {
                display:none;
              }

            }
            .date-desc{
                font-family: Plus Jakarta Sans;
                font-size: 16px;
                font-weight: 500;
                line-height: 20.16px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: ${theme.palette.customColor.primaryBlack};
            }
            }
          }
      
          .skill-chips-box {
            margin-top: 16px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            gap: 4px;
            @media screen and (max-width: 900px) {
              overflow-x:scroll;
            }
      
            .chips {
              background: #f4f4f4;
              padding: 4px 8px;
              font-family: Plus Jakarta Sans;
              font-size: 12px;
              font-weight: 500;
              line-height: 15.12px;
              text-align: left;
              border-radius: 40px;
              white-space: nowrap;
              margin-bottom: 8px;
            }
          }
      
          .card-bottom {
            .job-info-content {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            }
      
            .icon-text-box-wrap {
              display: flex;
              flex-direction: column;
              gap: 8px;
      
              .icon-text-box {
                display: inline-flex;
                align-items: center;
                gap: 4px;
              }
            }
      
            .sub-text {
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.64px;
              text-align: left;
            }
      
            .view-details {
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 600;
              line-height: 17.64px;
              text-align: left;
              text-decoration: underline;
              color: #415df3;
              cursor: pointer;
              min-width: 84px;
            }
      
            .date-text {
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.64px;
              text-align: left;
              color: #415df3;
              margin-top: 12px;
            }
        }
    }
    .divider{
      display:none;
      height:1px;
      background:#E2E4E7;
      margin:12px 0 20px 0;
      width:100%;
      @media screen and (max-width: 900px){
          display:block;
      }
    }
      
}
`
);

export default StyledJobDetailsPage;
