/* eslint-disable arrow-body-style */
import React from 'react';

const BackArrowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4994 12.0009C22.4994 12.1999 22.4204 12.3906 22.2797 12.5313C22.1391 12.6719 21.9483 12.7509 21.7494 12.7509L4.05989 12.7509L8.78039 17.4699C8.92122 17.6108 9.00034 17.8018 9.00034 18.0009C9.00034 18.2001 8.92122 18.3911 8.78039 18.5319C8.63956 18.6728 8.44855 18.7519 8.24939 18.7519C8.05022 18.7519 7.85922 18.6728 7.71839 18.5319L1.71839 12.5319C1.64854 12.4623 1.59313 12.3795 1.55532 12.2884C1.51751 12.1973 1.49805 12.0996 1.49805 12.0009C1.49805 11.9023 1.51751 11.8046 1.55532 11.7135C1.59313 11.6224 1.64854 11.5396 1.71839 11.4699L7.71839 5.46995C7.78812 5.40022 7.8709 5.3449 7.96201 5.30716C8.05312 5.26942 8.15077 5.25 8.24939 5.25C8.44855 5.25 8.63956 5.32912 8.78039 5.46995C8.92122 5.61078 9.00033 5.80178 9.00033 6.00095C9.00033 6.20011 8.92122 6.39112 8.78039 6.53195L4.05989 11.2509L21.7494 11.2509C21.9483 11.2509 22.1391 11.33 22.2797 11.4706C22.4204 11.6113 22.4994 11.802 22.4994 12.0009Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};

export default BackArrowIcon;
