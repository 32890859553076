//function to download file
export const downloadFile = (data, filename, type) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: `application/${type}` })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.${type}`);
  document.body.appendChild(link);
  link.click();
};

export function formatLabel(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^\w/, (c) => c.toUpperCase());
}
