import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const CustomTooltip = ({ title = '', placement = 'right', children = '', maxWidth, otherProps }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      enterTouchDelay={0}
      enterDelay={0}
      placement={placement}
      TransitionComponent={Zoom}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.customColor.primaryBlack,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.customColor.primaryBlack,
      borderRadius: '8px',
      boxShadow: '0px 7px 20px 0px rgba(40, 41, 61, 0.08)',
      padding: '12px 8px',
      color: theme.palette.customColor.primaryWhite,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      maxWidth: maxWidth || 'auto',
      // Media query for mobile devices
      '@media (max-width: 768px)': {
        padding: '8px 6px', // Reduce padding for smaller screens
        maxWidth: '250px', // Restrict the width to prevent overflow
      },
    },
  }));

  return (
    <BootstrapTooltip title={title} {...otherProps}>
      {children || (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          style={{ marginLeft: '8px' }}
          viewBox="0 0 20 21"
          fill="none">
          <path
            d="M10 18C14.1421 18 17.5 14.6421 17.5 10.5C17.5 6.35786 14.1421 3 10 3C5.85786 3 2.5 6.35786 2.5 10.5C2.5 14.6421 5.85786 18 10 18Z"
            stroke="#757575"
            strokeWidth="1.66667"
            strokeLinecap="round"
          />
          <path d="M10 13.8333V10.5" stroke="#757575" strokeWidth="1.66667" strokeLinecap="round" />
          <path
            d="M10 7.375V7.47917"
            stroke="#757575"
            strokeWidth="2.22222"
            strokeLinecap="round"
          />
        </svg>
      )}
    </BootstrapTooltip>
  );
};

export default CustomTooltip;
