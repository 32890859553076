import { createSlice } from "@reduxjs/toolkit";
import { ToastNotifyError } from "../../../components/Toast/ToastNotify";
import { fetchDashBoardData, fetchExpertList } from "./apiThunk";

const initialData = {
  userLandingData: {},
  expertList: [],
};
const expertProfileSlice = createSlice({
  name: "userData",
  initialState: { ...initialData },
  reducers: {
    resetExpertProfile: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashBoardData.fulfilled, (state, action) => {
      state.userLandingData = action.payload;
    });
    builder.addCase(fetchDashBoardData.rejected, (state, action) => {
      state.userLandingData = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchExpertList.fulfilled, (state, action) => {
      state.expertList = action.payload;
    });
    builder.addCase(fetchExpertList.rejected, (state, action) => {
      state.expertList = action.payload;
      ToastNotifyError(action.error.message);
    });
  },
});

const { reducer, actions } = expertProfileSlice;
const selectorExpertProfile = (state) => state.expertProfile;
const { resetExpertProfile } = actions;
export { resetExpertProfile, selectorExpertProfile };

export default reducer;
