import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import StyledTextField from './StyledTextField';
import '../../styles/_global.scss';

export default function TextInput({
  classes = '',
  label = '',
  id = '',
  icon = '',
  startIcon = '',
  maxLength,
  minLength,
  placeholderValue = '',
  important = false,
  wordCount = false,
  ...restData
}) {
  return (
    <StyledTextField className={classes} key={id}>
      <div className="input-Label_text label-space">
        {label}
        {important ? <span className="important">*</span> : ''}
      </div>
      <TextField
        fullWidth
        id={id}
        focused={false}
        autoFocus={false}
        className="custom-field"
        size="small"
        variant="standard"
        margin="normal"
        placeholder={placeholderValue}
        InputProps={{
          endAdornment: (
            icon
              ? (
                <InputAdornment position="end" className={wordCount ? 'wordCount' : ''}>
                  {icon}
                </InputAdornment>
              ) : (
                ''
              )),
          autoComplete: 'off',
          disableUnderline: true,
          maxLength,
          minLength,
          startAdornment: (
            startIcon
              ? (
                <InputAdornment position="start">
                  {startIcon}
                </InputAdornment>
              ) : (
                ''
              )
          ),
        }}
        {...restData}
        label=""
      />
    </StyledTextField>
  );
}
