/* eslint-disable arrow-body-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BackArrowIcon from '../../components/Icons/BackArrowIcon';
import StyledExpertDetails from './StyledExpertDetails';
import { BorderButton } from '../../components/Button';
import experienceIcon from '../../assets/svg/experinceIcon.svg';
import locationIcon from '../../assets/svg/locationIcon.svg';
import industryIcon from '../../assets/svg/industryIcon.svg';
import domainIcon from '../../assets/svg/domainIcon.svg';
import priceIcon from '../../assets/svg/priceCoinIcon.svg';
import infoIcon from '../../assets/svg/infoIcon.svg';
import CircleAvatar from '../../components/CircleAvatar';
import educationIcon from '../../assets/svg/educationIcon.svg';
import workIcon from '../../assets/svg/workIcon.svg';
import smallLocationIcon from '../../assets/svg/smallLocationIcon.svg';
import circleIcon from '../../assets/svg/circleTickIcon.svg';
import resumeIcon from '../../assets/svg/resumeIcon.svg';
import smallCalenderIcon from '../../assets/svg/smallCalenderIcon.svg';
import { getExpertDetails,getDownloadResume } from '../../store/reducers/userData/apiThunk';
import AppSidebar from '../../components/AppSidebar';
import AppHeader from '../../components/AppHeader';
import { checkCompensationType } from '../../helper';
import CustomTooltip from '../../components/Tooltip/Tooltip';
import  copyIcon from "../../assets/svg/copied.svg";
import backArrowMobile from '../../assets/svg/jobdetail/arrow-left.svg';
import arrowbg from '../../assets/images/arrowbg.png';
import servicebg from '../../assets/images/serviceicon.png';

const ExpertDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [expertId, setExpertId] = useState(null);
  const [applicant,setApplicant ] = useState();
  const [copyText, setCopyText] = useState('Copy expert profile link');

  const handleCopyUrl = async () => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/applicant/details?id=${expertId}`);
    setCopyText('Copied to clipboard');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setExpertId(params.get('id'));
    }
  }, [location]);

  const fetchExpertDetails =async()=>{
    const response = await dispatch(getExpertDetails(expertId));
    const { payload: { data:{data={}}, status } = {} } = response;
    console.log(data,"applicant data")
    if(status){
      setApplicant(data)
    }
  }

  useEffect(()=>{
    if(expertId){
      fetchExpertDetails()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[expertId])

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const durationCheck = (itemCount, item) => {
    const isPlural = itemCount > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  const DownloadResumePdf = async () => {
    const id = applicant?.expert?._id;
    if (!id) {
      console.error('Expert ID is missing.');
      return;
    }

    try {
      const res = await dispatch(getDownloadResume(id));
      if (res?.payload?.data?.status) {
        const fileUrl = res?.payload?.data?.data;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `${applicant?.expertDetails?.firstName}'s Resume`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error while downloading resume:', error);
    }
  };
  const size = window.innerWidth <= 768 ? '64px' : '130px';

  const JobDetaisInfo = ({ src, heading, detail }) => (
    <div className="info-wrap-mobile">
      <div className="info-heading">
        <img className="info-icon" src={src} alt="icon" />
        <span>
          {heading}
        </span>
      </div>
      <div className="info-detail">
        {detail}
      </div>
    </div>
  );

  if(!applicant) return;

  return (
    <>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100" style={{background:'#F8F4F0'}}>
    <AppHeader text="FRACTIONAL JOB EXPERTS" />
    <StyledExpertDetails>
      <div className="content-wrap">
        <div className="back-btn" onClick={() => navigate(-1)}>
          <BackArrowIcon />
          <div className="back-btn-text">Expert’s Profile</div>
      </div>
      <div className="page-title-mobile-wrap">
          <div className="content">
            <div className="left"> <img alt="arrow" src={backArrowMobile} onClick={() => navigate(-1)}/> <span>Recruiter’s Profile</span></div>
            <div className="right">
            <CustomTooltip title={copyText} placement="top">
                  <div
                    className="copyBtn"
                    onClick={() => handleCopyUrl()}
                    onMouseOut={() => setCopyText('Copy expert profile link')}
                    >
                    <img className="icon-image" src={copyIcon} alt="" />
                  </div>
                </CustomTooltip>
            </div>
          </div>
        </div>
        <div className="detail-card">
          <div className="img-back" />
          <div className="main-card">
            <div className="header">
              <div className="left-header">
                <div className="img">
                  <div className="img-wrap">
                    <CircleAvatar src={applicant?.expert?.profileImagePath} size ={size} />
                  </div>
                </div>
                <div className="applicant">
                  <div className="applicant-name">
                    {applicant?.expert?.firstName} {applicant?.expert?.lastName}
                  </div>
                  <div className="applicant-role">
                    <img src={educationIcon} alt="" />
                    {applicant?.jobRole}
                  </div>
                </div>
              </div>
              <div className="right-header">
              <CustomTooltip title={copyText} placement="top">
                  <div
                    className="copyBtn"
                    onClick={() => handleCopyUrl()}
                    onMouseOut={() => setCopyText('Copy expert profile link')}
                    >
                    <img className="icon-image" src={copyIcon} alt="" />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div className="main-body-card">
              <div className="main-body">
                <div className="my-services">
                    <img src={servicebg} alt='' style={{position:'absolute',left:'20px',top: '16px'}}/>
                    <img src={arrowbg} alt='' style={{position:'absolute', left:'107px', top:"7px"}}/>
                  <div>
                    <div>My Services</div>
                  </div>
                  <div>
                    <BorderButton
                      style={{ width: '150px', }}
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_URL}/services/${applicant?.expert?.username}`,
                          '_blank' // This specifies the new tab
                        );
                      }}>
                      Book a Call
                    </BorderButton>
                  </div>
                </div>
                <div className="body-header">Basic Details</div>

                <JobDetaisInfo src={experienceIcon} heading="Experience" detail={`${applicant?.experienceYears} Year`}/>
                <JobDetaisInfo src={industryIcon} heading="Current Location" detail={`${applicant?.city}, ${applicant?.state} `}/>
                <JobDetaisInfo src={locationIcon} heading="Industry" detail={applicant?.dndustry}/>
                <JobDetaisInfo src={domainIcon} heading="Domain" detail={applicant?.domain}/>

                <div className="body-topics">
                  <div className="each-topic">
                    <img src={experienceIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Experience</div>
                      <div className="topic-body">{`${applicant?.experienceYears} Year`}</div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={locationIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Current Location</div>
                      <div className="topic-body">
                        {`${applicant?.city}, 
                      ${applicant?.state} `}
                      </div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={industryIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Industry</div>
                      <div className="topic-body">{applicant?.industry}</div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={domainIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Domain</div>
                      <div className="topic-body">{applicant?.domain}</div>
                    </div>
                  </div>
                </div>
                <div className="divider"/>
                <div className="skills-head">Skills</div>
                <div className="skills">
                  {applicant?.skills?.map((item) => (
                    <div className="user-application-2">{item}</div>
                  ))}
                </div>
                <div className="about-me">About Me</div>
                <div className="about-desc">
                  {applicant?.experienceDescription}
                </div>
              </div>
              <div className="subs-cards">
                <div className="card-1">
                  <div className="card-head">Charges & Preferences</div>
                  <div className="price">
                    <img src={priceIcon} alt="" />
                    <div className="price-card">
                      <div className="price-text">{applicant?.compensation}</div>
                      <div className="price-duration">{`Fees per ${checkCompensationType(applicant?.compensationType)}`}</div>
                    </div>
                  </div>
                  <div className="nego">
                    <img src={infoIcon} alt="" />
                    <div className="nego-text">
                      This price is <span>{` ${applicant?.isRateNegotiable ? 'Negotiable' : 'Non-Negotiable'}!`}</span>
                    </div>
                  </div>
                  <hr />
                  <div className="subs">
                    <img src={workIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Work Preference</div>
                      <div className="subs-body">
                        {capitalizeWorkPreference(applicant?.workPreference)}
                      </div>
                    </div>
                  </div>
                  <div className="subs">
                    <img src={smallLocationIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Preferred Job Location</div>
                      <div className="subs-body">
                        {applicant?.preferredJobLocation}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-1" style={{ marginTop: '20px' }}>
                  <div className="card-head">Availability</div>
                  <div className="subs">
                    <img src={circleIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Available For</div>
                      <div className="subs-body">
                        {durationCheck(
                          applicant?.availability,
                          applicant?.availabilityType,
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="subs">
                    <img src={smallCalenderIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Active days in a month</div>
                      <div className="subs-body">{applicant?.availability}</div>
                    </div>
                  </div>
                </div>
                <div className="card-1" style={{ margin: '20px 0px' }}>
                  <div className="card-head">My Resume</div>
                  <div className="resume-card">
                    <div className="resume">
                      <img src={resumeIcon} alt="" />
                      <div className="resume-main">
                        <div className="resume-topic">
                          {`${applicant?.expert?.firstName}'s Resume`}
                        </div>
                        <div className="resume-body">DOC, 188KB</div>
                      </div>
                    </div>
                    <div>
                      <BorderButton onClick={() => DownloadResumePdf()}>View resume</BorderButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledExpertDetails>
    </div>
    </>
  );
};

export default ExpertDetails;
