import { styled } from "@mui/material/styles";

const StyledJobDetailsPage = styled("div")(
  ({ theme }) => `
.page-wrap{
    margin:32px 40px;
    @media screen and (max-width: 900px){
        margin:0px;
      }
    .page-title{
        text-align: left;
        margin-bottom:24px;
        img{
            cursor:pointer;
        }
        span{
            font-family: Plus Jakarta Sans;
            font-size: 24px;
            font-weight: 600;
            line-height: 30.24px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color:#1C1C1C;
            margin-left:12px;
        }
        @media screen and (max-width: 900px){
            display:none;
        }
    }
    .page-title-mobile-wrap{
        display:none;
        background: #ffffff;
        z-index: 1400;
        position: sticky;
        top: 0px;
        height: 79px;
        margin:-79px 0px 16px 0px;
        padding:16px 20px;
        .content{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
                display: flex;
                align-items: center;
                gap:12px;
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 700;
                line-height: 22.68px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: ${theme.palette.customColor.primaryBlack};
                img{
                    cursor:pointer;
                }
            }
            .right{
                display: flex;
                align-items: center;
                gap:12px;
                .icon-image{
                    width:32px;
                    height:32px;
                    cursor:pointer
                }
            }
        }
        @media screen and (max-width: 900px){
            display: flex;
        }
    }

    .job-details-wrap{
        position:relative;
        border-top:10px solid #FFD2AE;
        border-radius: 24px;
        background:#ffffff;
        .top-space{
            height:18px;
            background:#ffffff;
            border-radius: 7px;
        }
        .job-details-content-wrap{
            background:#ffffff;
            padding:0px 24px 24px;
            border-bottom-left-radius: 24px;
            border-bottom-right-radius: 24px;
            .job-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    
                    display: flex;
                    align-items: start;
                    flex-wrap:wrap;
                    flex-grow: 1;
                    gap:12px;
                    .brand-image{
                        width: 72px;
                        height: 72px;
                        border-radius: 50%;
                    }
                    .commpany-info{
                        max-width:530px;
                        .job-title{
                            display: flex;
                            gap:4px;
                            align-items: start;
                            font-family: Sora;
                            font-size: 28px;
                            font-weight: 700;
                            line-height: 35.28px;
                            text-align: left;                       
                            color: ${theme.palette.customColor.primaryBlack};
                            word-break:break-all;
                        }
                        .job-industry{
                            display: flex;
                            gap:4px;
                            align-items: center;
                            font-family: Plus Jakarta Sans;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 22.68px;
                            text-align: left;
                            color: #525252;
                            word-break:break-all;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: start;
                    flex-wrap:wrap;
                    gap:16px;
                    .date-title{
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color:#757575;
                    }
                    .date-desc{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.16px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: ${theme.palette.customColor.primaryBlack};
                    }
                    .icon-image{
                        width: 44px;
                        height: 44px;
                        cursor:pointer;
                    }
                }
                @media screen and (max-width: 900px){
                    display: none;
                }
            }
            .job-header-mobile{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left{
                    display: flex;
                    align-items: start;
                    flex-wrap:wrap;
                    flex-grow: 1;
                    gap:12px;
                    .brand-image{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }
                    .commpany-info{
                        max-width:calc(100% - 62px);
                        .job-title{
                            display: flex;
                            gap:4px;
                            align-items: start;
                            font-family: Sora;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 25.2px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;                                                  
                            color: ${theme.palette.customColor.primaryBlack};
                            word-break:break-word;
                        }
                        .job-industry{
                            display: flex;
                            gap:4px;
                            align-items: center;
                            font-family: Plus Jakarta Sans;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17.68px;
                            text-align: left;
                            color: #525252;
                            word-break:break-all;
                        }
                    }
                    .date-title{
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color:#757575;
                    }
                    .date-desc{
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: ${theme.palette.customColor.primaryBlack};
                    }
                }
                display:none;
                @media screen and (max-width: 900px){
                    display: flex;
                }
            }

            //  card content

            .section-title{
                font-family: Plus Jakarta Sans;
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: ${theme.palette.customColor.primaryBlack};
                margin:32px 0px 16px 0px; 
                @media screen and (max-width: 900px){
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20.16px;
                    margin:0px 0px 16px 0px;
                }
            }
            .info-wrap{
                display: flex;
                align-items: start;
                flex-wrap:wrap;
                gap:7px;
                margin-bottom:16px;
                .info-heading{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color:#757575;
                }
                .info-detail{
                    font-family: Sora;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22.68px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    margin-top:4px;
                }
                @media screen and (max-width: 900px){
                    display: none;
                }

            }
            .info-wrap-mobile{
               
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap:7px;
                margin-bottom:16px;
                .info-heading{
                    display: flex;
                    align-items: center;
                    gap:7px;
                    font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15.12px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;                    
                    color:#757575;
                    .info-icon{
                        width:24px;
                        height:24px;
                    }
                   
                }
                .info-detail{
                    font-family: Sora;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 15.12px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    margin-top:4px;
                }
                display:none;
                @media screen and (max-width: 900px){
                    display: flex;
                }
            }
            .about-wrap{
                text-align:left;
                .about-heading{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color:#757575;
                }
                .about-detail{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    white-space: pre-wrap;
                    margin-top:16px;
                      @media screen and (max-width: 900px){
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                    }
                }
            }
            .info-card-wrap{
                padding:20px;
                background-color:#F8F4F0;
                border-radius:20px;
                .info-heading{
                    font-family: Plus Jakarta Sans;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25.2px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: ${theme.palette.customColor.primaryBlack};
                    margin-bottom:24px;
                    @media screen and (max-width: 900px){
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20.16px;
                    }
                }
                // charges
                .charges-wrap{
                    text-align: left;
                    .price{
                        display:inline-flex;
                        align-items: center;
                        flex-wrap:wrap;
                        gap:4px;
                        font-family: Sora;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 20px;
                        text-align: left;
                        color: ${theme.palette.customColor.primaryBlack};
                    }
                    .fees{
                        margin-left:28px;
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17.64px;
                        text-align: left;
                    }
                    .negotiable{
                        display:inline-flex;
                        align-items: center;
                        flex-wrap:wrap;
                        gap:4px;
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 15.12px;
                        text-align: left;
                        color:#525252;
                        .blue-text{
                            color:#415DF3;
                        }
                        margin-bottom:10px;
                    }
                }
                .availability-wrap{
                    text-align: left;
                    .availability-heading{
                        display:inline-flex;
                        align-items: center;
                        flex-wrap:wrap;
                        gap:4px;
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        color: #757575;
                        @media screen and (max-width: 900px){
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 15px;
                        }
                    }
                    .availability-detail{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.16px;
                        text-align: left;                     
                        color: ${theme.palette.customColor.primaryBlack};
                        margin-left:22px;
                        @media screen and (max-width: 900px){
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }
                }
                .company-wrap{
                    text-align: left;
                    .company-heading{
                        display:inline-flex;
                        align-items: center;
                        flex-wrap:wrap;
                        gap:4px;
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17.64px;
                        text-align: left;
                        color: #757575;
                        @media screen and (max-width: 900px){
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 15px;
                        }
                    }
                    .company-detail{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20.16px;
                        text-align: left;                     
                        color: ${theme.palette.customColor.primaryBlack};
                        margin-left:22px;
                        @media screen and (max-width: 900px){
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }
                    .job-links{
                        font-family: Plus Jakarta Sans;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20.16px;
                        text-align: left;
                        color:#415DF3;
                        max-width:305px;
                        text-overflow: ellipsis;
                      }
                    .contact{
                        display:flex;
                        align-items:center;
                        justify-content: space-between;
                    }
                }

                .skill-wrap{
                    marging-top:16px;
                    margin-bottom:12px;
                    display:flex;
                    align-items:center;
                    gap:4px;
                    flex-wrap:wrap;
                    .chips{
                        background:#FFFFFF;
                        padding:4px 8px;
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15.12px;
                        text-align: left;
                        border-radius:40px;
                        white-space: nowrap;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
    .mt-16{
        margin-top:16px;
    }
    .divider{
        display:none;
        height:1px;
        background:#E2E4E7;
        margin:20px 0 20px 0;
        width:100%;
        @media screen and (max-width: 900px){
            display:block;
        }
    }
}
`
);

export default StyledJobDetailsPage;
