import React, {useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Chip,
  Box,
} from "@mui/material";
import "./CustomTable.scss";
import { PrimaryButtonBlue } from "../Button";
import Modal from "../Modal";
import { useNavigate } from 'react-router-dom';


export const SESSION_STATUS_ENUM = {
  PENDING: 1,
  IN_PROGRESS: 2,
  UPCOMING: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  RACE: 6,
};

// Map numeric values to status strings
const STATUS_MAP = {
  [SESSION_STATUS_ENUM.PENDING]: "PENDING",
  [SESSION_STATUS_ENUM.IN_PROGRESS]: "IN_PROGRESS",
  [SESSION_STATUS_ENUM.UPCOMING]: "UPCOMING",
  [SESSION_STATUS_ENUM.COMPLETED]: "COMPLETED",
  [SESSION_STATUS_ENUM.CANCELLED]: "CANCELLED",
  [SESSION_STATUS_ENUM.RACE]: "RACE",
};

export const getStatusChip = (status) => {
  const statusString = STATUS_MAP[status]; // Convert numeric status to string

  switch (statusString) {
    case "COMPLETED":
      return (
        <Chip label="COMPLETED" color="success" className="completedChip" />
      );
    case "CANCELLED":
      return <Chip label="CANCELLED" color="error" className="cancelledChip" />;
    case 'UPCOMING':
      return <Chip label="UPCOMING" color="error" className="pendingChip" />;
    default:
      return <Chip color="info" label={status} />; // Handle other statuses or invalid values
  }
};


export default function ExpertTable({
  sessionsData = [],
  tableHeaderRight = false,
  totalExperts=0,
}) {
  const navigate = useNavigate();
  const [isOpenSubscription, setisOpenSubscription] = useState(false);
  const handleViewDetails =(id)=>{
    navigate(`/expert?id=${id}`)
  }
  const handleCloseSubscription=()=>setisOpenSubscription(false)

  return (
    <>
      <Box sx={{ padding: 3 }} className="fractionTableWrapper">
        <TableContainer component={Paper}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            className="table-header-wrap"
          >
            <Typography variant="h6">Total Experts: {totalExperts}</Typography>
            {tableHeaderRight ? tableHeaderRight : ""}
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionsData.length ? sessionsData?.map((session, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell className="typography-cell">
                    {session.firstName + ' ' + session.lastName}
                  </TableCell>
                  <TableCell className="typography-cell">
                    <Typography variant="body1">
                        {session?.phoneNumber}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {session.primaryEmail}
                      </Typography>
                    </TableCell>
                  <TableCell className="typography-cell">
                    {<span className="free-chip">Free</span>}
                    </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="primary"
                      className="tableButton"
                      onClick={()=>{
                        setisOpenSubscription(true)
                      }}
                    >
                      View Plan
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="primary"
                      className="tableButton"
                      onClick={()=>handleViewDetails(session._id)}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              )):null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

    <Modal
        openDialog={isOpenSubscription}
        closeDialog={handleCloseSubscription}
        heading={<div className="title">Subscription Detail</div>}
        dialogContent={
          <div className="content-wrapper">
            <div className="wrap-div">
            <div className="subs-wrap">
            <div className="subs-card">
              <div className="select-plan">
                <div className="plan-duration">Half-Yearly Plan</div>
              </div>
              <div className="plan-amount">
                Rs 1199 <span>For 6 months</span>
              </div>
              <PrimaryButtonBlue style={{width:'198px'}}>View Details</PrimaryButtonBlue>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription Start Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription End Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
          </div>
            </div>
          </div>
        }
        closeButVisible
        dialogCss="accountDetail"
      />
    </>
  );
}
