import { styled } from '@mui/material/styles';

const StyledTextField = styled('div')(
  ({ theme }) => `
.custom-field {
  border-radius: 12px;
}
.input-Label_text {
  color: ${theme.palette.customColor.primaryWhite};
  text-align: left;
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 700; 
  font-size: 12px;
  line-height: 14px;
}
.MuiInputBase-root {
  border-radius: 4px !important;
  border: 1px solid ${theme.palette.customColor.secondaryThree};
  padding: 0px;
  align-items: flex-end;
}
.MuiOutlinedInput-root:hover {
  border: none !important;
}
.MuiIconButton-root {
  margin-right: 0 !important;
}
.MuiFormControl-root {
  background-color: transparent !important;
}
  
.MuiFormControl-root {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
}
  
.MuiInput-input {
  padding: 10px !important;
  /*background: #f5f5f7e6 !important; */
  border-radius: 10px !important;
  color: ${theme.palette.customColor.secondaryGreyText};
}
.MuiInput-input:focus {
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 4px !important; 
}
 .MuiSvgIcon-root {
  color: ${theme.palette.primary.main};
  margin-right: 10px;
  width: 20px;
 } 
.MuiFormHelperText-root.Mui-error {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #ff3c3c !important;
}
.Mui-disabled {
  background: #3F3F3F;
  color: #A6A6A6;
  -webkit-text-fill-color: unset !important;
}
.important{
  color: #DA3333;
}
.wordCount{
  position: absolute;
  right: 0px;
  padding: 12px;
  height: auto;
  max-height: none;
  p{
    font-weight: 400;
    font-size: 12px;
    color: #A6A6A6;
  }
}
`,
);

export default StyledTextField;
