import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

const StyledModal = styled(Dialog)(
  () => `
&.dialogBox {
    background: #f8f6f669;
  .MuiDialog-paper {
    box-shadow: 0px 7px 20px rgba(9, 9, 10, 0.15) !important;
    border-radius: 10px !important;
    background: white;
    color: black;
    .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      padding: 23px 26px !important;
      .dialog-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        padding-left: 32px;
       }
       .crossIcon {
          cursor: pointer;
          display: flex;
          align-items: center;
       }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .dialogBox {
      .MuiDialog-paper {
        .MuiDialogTitle-root {
          .dialog-title {
            padding: 0;
          }
        }
      }
    }
  }
}

.logoutDialogWrap{
  .MuiDialog-paper {
    width: 30%;
  }
  .MuiDialogContent-root {
    text-align: center;
    .logout-button-wrap{
      display: flex;
      margin-top: 40px;
      margin-bottom: 15px;
      justify-content: space-evenly;
    }
  }
}
`
);
export default StyledModal;
