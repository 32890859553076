import React from 'react';

const CircleAvatar = ({ src, name, size = 40 }) => {
  const initial = name && name.trim() ? name.charAt(0).toUpperCase() : '';

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        color: '#555',
        fontSize: size / 2.5,
        fontWeight: 'bold',
        overflow: 'hidden',
        textTransform: 'uppercase',
      }}>
      {src ? (
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}/${src}`}
          alt={name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
          }}
        />
      ) : (
        <span>{initial || 'C'}</span>
      )}
    </div>
  );
};

export default CircleAvatar;
